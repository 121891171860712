import CircularLoader from '@capturi/assets/images/CircularLoader.svg'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const NotConnected: React.FC = () => {
  return (
    <>
      <Flex
        alignItems="center"
        justify="center"
        height="100%"
        flexDirection="column"
      >
        <CircularLoader width="190px" height="190px" />
        <Text fontSize="lg">
          <Trans>Pending...</Trans>
        </Text>
        <Text>
          <Trans>Start a conversation</Trans>
        </Text>
      </Flex>
    </>
  )
}

export default NotConnected
