import CircularLoader from '@capturi/assets/images/CircularLoader.svg'
import { Flex, HStack, IconButton, Text, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { format } from 'date-fns/fp'
import { usePostHog } from 'posthog-js/react'
import React, { useState } from 'react'
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md'
import KeyTopicTitle from './styles/KeyTopicTitle'
import MagicBox from './styles/MagicBox'
import MagicBoxHeader from './styles/MagicBoxHeader'

type Props = {
  subTopic: string | null
  topic: string | null
  summary: string | null
  isLoading: boolean
  error: Error | null
  date: Date
}

const formatTime = format('hh:MM')

const VoiceBot: React.FC<Props> = ({
  error,
  isLoading,
  subTopic,
  summary,
  topic,
  date,
}) => {
  const toast = useToast()
  const [isReviewed, setIsReviewed] = useState(false)
  const posthog = usePostHog()
  if (isLoading)
    return (
      <Flex justifyContent="center">
        <CircularLoader width="90px" height="90px" />
      </Flex>
    )
  if (error) return <Text>{error.message}</Text>

  return (
    <MagicBox>
      <MagicBoxHeader flexDir="row" justifyContent="space-between">
        <KeyTopicTitle keyTopic={topic} subKeyTopic={subTopic} />
        <Text>{formatTime(date)}</Text>
      </MagicBoxHeader>
      <HStack pl="1" pr="3" justify="space-between">
        <Text whiteSpace="break-spaces" p="3">
          {summary || t`No details available`}
        </Text>
        {!isReviewed && summary && (
          <HStack gap="1">
            <IconButton
              aria-label="Thumbs up"
              icon={<MdOutlineThumbUp />}
              size="xs"
              variant="ghost"
              color="gray.500"
              onClick={(e) => {
                e.stopPropagation()
                posthog?.capture('voiceBot_ThumbsUp', {
                  summary: summary,
                  keyTopic: topic,
                  subTopic: subTopic,
                  date: date,
                })
                setIsReviewed(true)
                toast({
                  title: t`Thank you for the feedback`,
                  description: t`You are helping us to improve future summaries`,
                  status: 'success',
                  duration: 4000,
                  isClosable: true,
                })
              }}
            />
            <IconButton
              aria-label="Thumbs down"
              fontSize="8px"
              icon={<MdOutlineThumbDown />}
              size="xs"
              variant="ghost"
              color="gray.500"
              onClick={(e) => {
                e.stopPropagation()
                posthog?.capture('voiceBot_ThumbsDown', {
                  summary: summary,
                  keyTopic: topic,
                  subTopic: subTopic,
                  date: date,
                })
                setIsReviewed(true)
                toast({
                  title: t`Thank you for the feedback`,
                  description: t`You are helping us to improve future summaries`,
                  status: 'success',
                  duration: 4000,
                  isClosable: true,
                })
              }}
            />
          </HStack>
        )}
      </HStack>
    </MagicBox>
  )
}

export default VoiceBot
