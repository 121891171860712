import { UseQueryResult, useQuery } from '@tanstack/react-query'
import ky from 'ky'

import { API_PREFIX } from '../../config'
import { RequestError, extractHttpError } from './extractHttpError'
import { log } from './log'

export type Config = {
  waitForConnectionBeforeShowingSummary: boolean
  debugLogging: boolean
}

//documentation can be found here:
// https://api.capturi.ai/puzzel-widget/documentation/swagger
export const useConfig = (
  apiToken: string | null,
): UseQueryResult<Config, RequestError> =>
  useQuery({
    enabled: !!apiToken,
    queryKey: [apiToken, 'config'],
    queryFn: async () => {
      try {
        return await ky
          .get(`${API_PREFIX}/puzzel-widget/config`, {
            credentials: 'include',
            mode: 'cors',
            headers: {
              Authorization: apiToken || undefined,
            },
          })
          .json<Config>()
      } catch (error) {
        const e = await extractHttpError(error)

        log(apiToken, {
          data: {
            href: window.location.href,
            name: e.name,
            message: e.message,
            ...e.cause,
          },
          level: 'error',
          message: 'useConfig error',
        })

        return {
          waitForConnectionBeforeShowingSummary: true,
          debugLogging: false,
        }
      }
    },
  })

export const getConfig = async (apiToken: string): Promise<Config> => {
  try {
    return await ky
      .get(`${API_PREFIX}/puzzel-widget/config`, {
        credentials: 'include',
        retry: 10,
        mode: 'cors',
        headers: {
          Authorization: apiToken || undefined,
        },
      })
      .json<Config>()
  } catch (error) {
    const e = await extractHttpError(error)
    log(apiToken, {
      data: {
        href: window.location.href,
        name: e.name,
        message: e.message,
        ...e.cause,
      },
      level: 'error',
      message: 'getConfig error',
    })

    return {
      waitForConnectionBeforeShowingSummary: true,
      debugLogging: false,
    }
  }
}
