import { Divider as CDivider, Flex, Text } from '@chakra-ui/react'
import { useLingui } from '@lingui/react'
import React from 'react'

type Props = { date: string }

const Divider: React.FC<Props> = ({ date }) => {
  const { i18n } = useLingui()
  return (
    <Flex pt="5" alignItems="center">
      <CDivider />
      <Text px="4" whiteSpace="nowrap">
        {i18n.date(date, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </Text>
      <CDivider />
    </Flex>
  )
}

export default Divider
