import { Flex, type FlexboxProps } from '@chakra-ui/react'
import React from 'react'

type Props = { children?: React.ReactNode }

const MagicBoxHeader: React.FC<Props & FlexboxProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Flex
      background="gray.50"
      py="2"
      px="4"
      width="100%"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="gray.200"
      flexDir="column"
      {...restProps}
    >
      {children}
    </Flex>
  )
}

export default MagicBoxHeader
