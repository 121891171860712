import { Box, type BoxProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'

export const MagicBox = React.forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => {
    return (
      <Box
        boxShadow="0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A"
        background="white"
        borderRadius="8px"
        position="relative"
        overflow="hidden"
        p="1px"
        w="100%"
        css={css`
::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px; 
  padding: 1px; 
  overflow: hidden;
  background:linear-gradient(
    180deg,
    rgba(239, 58, 66, 0.5) 0%,
    rgba(255, 178, 32, 0.5) 49%,
    rgba(255, 145, 179, 0.5) 100%
  ); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
`}
        {...props}
        ref={ref}
      />
    )
  },
)

export default MagicBox
