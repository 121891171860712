import { Emoji } from '@capturi/ui-components'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const NoConversations: React.FC = () => {
  return (
    <Flex alignItems="center" mt="30%" flexDirection="column">
      <Text fontSize="2xl">
        <Trans>No previously recorded calls</Trans>
        <Emoji symbol="📞" fontSize="3xl" pl={2} />
      </Text>
      <Text fontSize="lg" color="gray.600">
        <Trans>There are no previous recordings with this customer.</Trans>
      </Text>
    </Flex>
  )
}

export default NoConversations
