import { Flex } from '@chakra-ui/react'
import type { Conversation } from 'components/api/useConversations'
import Divider from 'components/styles/Divider'
import React from 'react'
import SingleConversation from './SingleConversation'

type Props = { date: string; conversations: Conversation[] }

const Conversations: React.FC<Props> = ({ conversations, date }) => {
  return (
    <Flex gap="4" flexDir="column">
      <Divider date={date} />
      {conversations.map(
        (c) =>
          c.aiInsights.customSummary && (
            <SingleConversation
              agent={c.agent}
              dateTime={c.dateTime}
              duration={c.duration}
              subject={c.subject}
              customSummary={c.aiInsights.customSummary}
              conversationUid={c.conversationUid}
              key={c.dateTime}
              team={c.team}
              keyTopic={c.aiInsights.keyTopic}
              subKeyTopic={c.aiInsights.keySubTopic}
              rootCause={c.aiInsights.rootCause}
            />
          ),
      )}
    </Flex>
  )
}

export default Conversations
