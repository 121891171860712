import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

type Props = { error: Error }

const ErrorComponent: React.FC<Props> = ({ error }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt="10%"
    >
      <Text fontSize="xx-large">{error.name}</Text>
      <Text>{error.message}</Text>
      {error.cause != null && (
        <Text maxWidth="70%" mt="8" as="samp" whiteSpace="pre-wrap">
          {JSON.stringify(error.cause, null, 3)}
        </Text>
      )}
    </Flex>
  )
}

export default ErrorComponent
