import { Box } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import URLLayer from 'URLLayer'
import PuzzelLayer from './PuzzelLayer'
import TestLayer from './TestLayer'
import CapturiFooter from './components/CapturiFooter'
import CapturiThemeProvider from './components/CapturiThemeProvider'
import { POSTHOG_API_KEY, isDev } from './config'
import { messages as messagesDa } from './locales/da/messages.mjs'
import { messages as messagesEn } from './locales/en/messages.mjs'
import { messages as messagesNo } from './locales/no/messages.mjs'
import { messages as messagesSe } from './locales/se/messages.mjs'

const queryClient = new QueryClient()
const postHogOptions: Partial<PostHogConfig> = {
  api_host: 'https://posthog.capturi.ai',
  disable_session_recording: true,
  request_batching: true,
  autocapture: false,
}

const container = document.getElementById('app-root')

i18n.load({
  da: messagesDa,
  en: messagesEn,
  no: messagesNo,
  se: messagesSe,
})
i18n.activate('en')

//poor mans router
const getCurrentRoute = () => {
  if (isDev) return <TestLayer />
  //For legacy reasons; there are still som widgets on the puzzel-widget.capturi.ai subdomain
  const isPuzzel = window.location.host.includes('puzzel-widget')
  if (isPuzzel) return <PuzzelLayer />

  const path = window.location.pathname.split('/')[1] || ''
  switch (path) {
    case 'demo':
      return <TestLayer />
    case 'puzzel':
      return <PuzzelLayer />
    default:
      return <URLLayer />
  }
}

// biome-ignore lint/style/noNonNullAssertion: <explanation>
const root = createRoot(container!)
root.render(
  <StrictMode>
    <PostHogProvider apiKey={POSTHOG_API_KEY} options={postHogOptions}>
      <QueryClientProvider client={queryClient}>
        <CapturiThemeProvider>
          <I18nProvider i18n={i18n}>
            <Box
              bg="gray.50"
              width="100vw"
              height="calc(100vh - 45px)" //45 is the footer height
              overflow="scroll"
            >
              {getCurrentRoute()}
            </Box>
          </I18nProvider>
          <CapturiFooter />
        </CapturiThemeProvider>
      </QueryClientProvider>
    </PostHogProvider>
  </StrictMode>,
)
