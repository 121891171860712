export const extractLocale = (
  locale: unknown,
): 'da' | 'se' | 'no' | 'en' | null => {
  if (typeof locale !== 'string') return null

  switch (locale.slice(0, 2)) {
    case 'da':
      return 'da'
    case 'en':
      return 'en'
    case 'sv':
      return 'se'
    case 'se':
      return 'se'
    case 'no':
      return 'no'
    default:
      return null
  }
}
