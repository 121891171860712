import CapturiLogo from '@capturi/assets/images/logo/capturi_w-text_colored.svg'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const NoFirstConversation: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      justify="center"
      height="100%"
      flexDirection="column"
    >
      <Text fontSize="2xl">
        <Trans>Start a conversation</Trans>
      </Text>
      <Text fontSize="lg" color="gray.600">
        <Trans>... and view summaries of previous conversations here.</Trans>
      </Text>
      <Flex
        mt="12"
        justify="center"
        w="100%"
        filter="drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1)) 
         drop-shadow(0 2px 2px rgba(0, 0, 0, 0.09))  
         drop-shadow(0 5px 3px rgba(0, 0, 0, 0.05))
         drop-shadow(0 10px 4px rgba(0, 0, 0, 0.01))
         drop-shadow(0 15px 4px rgba(0, 0, 0, 0));"
      >
        <CapturiLogo width="170px" height="100%" />
      </Flex>
    </Flex>
  )
}

export default NoFirstConversation
