import { UseQueryResult, useQuery } from '@tanstack/react-query'
import ky from 'ky'
import qs from 'query-string'

import { API_PREFIX } from '../../config'
import { RequestError, extractHttpError } from './extractHttpError'
import { log } from './log'

const groupByDate = (
  conversations: Conversation[],
): [string, Conversation[]][] => {
  return Object.entries(
    conversations.reduce<Record<string, Conversation[]>>((memo, c) => {
      if (!c.aiInsights.customSummary) return memo

      const date = c.dateTime.split('T')[0]
      const current = memo[date] || []
      current.push(c)

      memo[date] = current

      return memo
    }, {}),
  )
}

//documentation can be found here:
// https://api.capturi.ai/puzzel-widget/documentation/swagger

export type Filter = {
  adversusLeadId?: string
  customer?: string
  customers?: string
}
export const useConversations = (
  apiToken: string,
  filter: Filter | undefined,
): UseQueryResult<[string, Conversation[]][] | undefined, RequestError> =>
  useQuery({
    enabled: !!(
      apiToken &&
      (filter?.adversusLeadId || filter?.customer || filter?.customers)
    ),
    queryKey: [apiToken, 'conversations', filter],
    queryFn: async () => {
      try {
        return await ky
          .get(
            qs.stringifyUrl(
              {
                url: `${API_PREFIX}/puzzel-widget/summaries`,
                query: filter,
              },
              { skipEmptyString: true, skipNull: true },
            ),
            {
              credentials: 'include',
              mode: 'cors',
              retry: 30,
              headers: {
                Authorization: apiToken,
              },
            },
          )
          .json<Conversation[]>()
      } catch (error) {
        const e = await extractHttpError(error)
        log(apiToken, {
          data: { name: e.name, message: e.message, ...e.cause },
          level: 'error',
          message: 'useConversations error',
        })

        throw e
      }
    },
    select: groupByDate,
  })

export type Conversation = {
  organizationUid: string
  agent: {
    email: string
    name: string
  }
  team?: string
  subject: string
  duration: number
  dateTime: string
  conversationUid: string
  aiInsights: {
    keyTopic?: string
    keySubTopic?: string
    rootCause?: string
    customSummary: string
  }
}
