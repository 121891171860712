import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useEffect } from 'react'

import { extractLocale } from 'components/extractLocale'
import { usePostHog } from 'posthog-js/react'
import Widget from './Widget'
import ErrorComponent from './components/ErrorComponent'

const URLLayer: React.FC = () => {
  const { i18n } = useLingui()
  const posthog = usePostHog()

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const token = params.get('token')
  const customer = params.get('customer') || ''
  const customers = params.get('customers') || ''
  const adversusLeadId = params.get('adversus-lead-id') || ''

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on init
  useEffect(() => {
    const organizationUid = params.get('org')
    if (organizationUid) {
      posthog?.identify(organizationUid)
    }

    const language = navigator.languages.find((l) => extractLocale(l) !== null)
    const langCode = extractLocale(language)
    if (langCode) i18n.activate(langCode)
  }, [])

  return (
    <>
      {token ? (
        <Widget
          apiToken={token}
          filter={{ customer, adversusLeadId, customers }}
        />
      ) : (
        <ErrorComponent error={new Error(t`Missing api token`)} />
      )}
    </>
  )
}

export default URLLayer
