import { HTTPError } from 'ky'

export const extractHttpError = async (
  error: unknown,
): Promise<RequestError> => {
  let errText = 'Unknown error'

  if (error instanceof HTTPError) {
    const url = error.request.url
    const { message, name, cause } = error
    try {
      errText = await error.response.text()

      const err = JSON.parse(errText)
      if (typeof err === 'string') {
        return new RequestError(message, {
          url: url,
          errorName: name,
          errorMessage: err || message,
          cause,
        })
      }

      return new RequestError(message, {
        url: url,
        errorName: name,
        cause: { cause, ...err },
      })
    } catch (_) {
      return new RequestError(message, {
        url: url,
        errorMessage: errText || message,
        cause,
      })
    }
  }

  if (error instanceof Error) {
    const { message, name, cause } = error

    return new RequestError(message, {
      errorName: name,
      cause,
    })
  }

  if (typeof error === 'string') {
    return new RequestError(error, {})
  }

  return new RequestError('Unknown Error', error as Record<string, unknown>)
}

export class RequestError extends Error {
  cause: Record<string, unknown>

  constructor(message: string, cause: Record<string, unknown>) {
    super(message)
    this.name = 'RequestError'
    this.cause = cause
  }
}
