import { Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdArrowForwardIos } from 'react-icons/md'

type Props = {
  keyTopic: string | undefined | null
  subKeyTopic: string | undefined | null
}

const KeyTopicTitle: React.FC<Props> = ({ keyTopic, subKeyTopic }) => {
  return (
    <Flex flex="2 0" gap="1" alignItems="baseline" whiteSpace="nowrap">
      {keyTopic && <Text fontWeight={500}>{keyTopic}</Text>}
      {keyTopic && subKeyTopic && (
        <Icon boxSize="10px" as={MdArrowForwardIos} />
      )}
      {subKeyTopic && <Text fontWeight={500}>{subKeyTopic}</Text>}
    </Flex>
  )
}

export default KeyTopicTitle
