import CapturiLogo from '@capturi/assets/images/logo/capturi_w-text_colored.svg'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

const CapturiFooter: React.FC = () => {
  return (
    <Flex
      height="45px"
      bg="gray.50"
      justifyContent="center"
      borderTop="1px solid"
      borderColor="gray.200"
    >
      <Flex alignItems="center">
        <Text color="gray.600" mr="2">
          Powered by
        </Text>
        <Box
          filter="drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1)) 
         drop-shadow(0 2px 2px rgba(0, 0, 0, 0.09))  
         drop-shadow(0 5px 3px rgba(0, 0, 0, 0.05))
         drop-shadow(0 10px 4px rgba(0, 0, 0, 0.01))
         drop-shadow(0 15px 4px rgba(0, 0, 0, 0));"
        >
          <CapturiLogo />
        </Box>
      </Flex>
    </Flex>
  )
}

export default CapturiFooter
