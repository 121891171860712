import { API_PREFIX, isDev } from 'config'
import ky from 'ky'

type LogOptions = {
  level: 'warn' | 'error' | 'info'
  message: string
  data: unknown
}

export const log = async (
  apiToken: string | undefined | null,
  payload: LogOptions,
): Promise<void> => {
  try {
    if (!isDev) {
      await ky.post(`${API_PREFIX}/puzzel-widget/hedwig`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          Authorization: apiToken || undefined,
        },
        json: payload,
      })
    }
  } catch (_) {
    /* empty */
  }
}
