import { Divider as CDivider, Flex, Text } from '@chakra-ui/react'

import React, { type ReactNode } from 'react'

type Props = { children: ReactNode }

const HeaderDivider: React.FC<Props> = ({ children }) => {
  return (
    <Flex py="5" alignItems="center">
      <CDivider borderColor="gray.400" />
      <Text px="4" whiteSpace="nowrap" fontSize="xl">
        {children}
      </Text>
      <CDivider borderColor="gray.400" />
    </Flex>
  )
}

export default HeaderDivider
